import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../../components/layout'; 
import HubspotForm from '../../components/hubspot-form'
import DangerousHTML from 'react-dangerous-html';

const Kontakt = (props) => {
  const data = props.data.markdownRemark;
  const { 
    meta, 
    title, 
    hubspotForm
  } = data.frontmatter;
  return (
    <Layout meta={meta}>
      <header data-aos='fade-in' className='page-title'>
        <h1>
          { title }
          <span style={{width: 100, height: 6, margin: '8px auto 0', display: 'block', backgroundColor: '#29699B'}}></span>
        </h1>
      </header>
      <div className='contact-content page-content'>
        {data.html && <DangerousHTML html={data.html} />}
        <div className='vertical-rule' />
        <HubspotForm 
          portalId={hubspotForm.portalId}
          formId={hubspotForm.formId}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: {title: {eq: "Kontakt"}}) {
    	frontmatter{
        title
        hubspotForm {
          portalId
          formId
        }
        meta {
          title
          description
          keywords
          robots
        }
      }
      html
    }
  }
`

export default Kontakt; 